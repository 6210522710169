<template>
  <div class="page">
    <van-skeleton
      :loading="$store.state.ajaxLoading"
      :row="20"
      title
    >
      <van-empty
        description="记录为空"
        v-if="!list || !list.length"
      />
      <van-cell-group
        :key="index"
        :title="item.buytime"
        v-for="(item, index) in list"
      >
        <van-cell
          :value="item.type_text"
          title="充值方式"
        ></van-cell>
        <van-cell
          :value="item.card_no"
          title="充值卡号"
        ></van-cell>
        <van-cell
          :value="item.money+' 元'"
          title="充值金额"
        ></van-cell>
        <van-cell
          :value="item.cardfen+' 点'"
          title="购买点数"
        ></van-cell>
      </van-cell-group>
      <div class="mv-15">
        <van-pagination
          :items-per-page="size"
          :total-items="total"
          @change="getList"
          mode="simple"
          v-model="page"
        />
      </div>
    </van-skeleton>
  </div>
</template>

<script>
import Vue from "vue";
import { CellGroup, Cell, Pagination, Empty } from "vant";
Vue.use(CellGroup).use(Cell).use(Pagination).use(Empty);
export default {
  name: "BuyBak",
  data() {
    return {
      total: 0,
      page: 1,
      size: 20,
      list: [],
    };
  },
  methods: {
    getList() {
      const params = {
        page: this.page,
        size: this.size,
        loading: true,
      };
      this.$http.get("user/recharge_logs", params).then((res) => {
        const { data, total } = res;
        this.total = total;
        this.list = data;
      });
    },
  },
  activated() {
    this.getList();
  },
};
</script>
